import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faExternalLinkAlt, faPhone, faHeart } from "@fortawesome/free-solid-svg-icons"

import { siteMetadata } from "../../gatsby-config"
import Layout from "../components/layout"
import SEO from "../components/seo"

import React, { Component } from "react"

export default class Page extends Component {
  render() {
    console.log(this.props.data)
    return (<Layout container={true} isDark={true} hasDarkColorScheme={true}>
        <div>
          <SEO title="AGB" />
          <a name="verantwortliche" style={{ top: 0, marginTop: 0 }}></a>
          <h1 className="header-page">AGB</h1>

          <div className="has-sidenav">

            <nav className={"sidenav-stuffed"}>
              <li><a href={"#gegenstand"}>Gegenstand</a></li>
              <li><a href={"#vertragsschluss"}>Vertragsschluss</a></li>
              <li><a href={"#urheberrecht"}>Urheberrecht</a></li>
              <li><a href={"#ideenschutz"}>Ideenschutz</a></li>
              <li><a href={"#verguetung"}>Vergütung</a></li>
              <li><a href={"#zusatzleistungen"}>Zusatzleistungen</a></li>
              <li><a href={"#geheimhaltung"}>Geheimhaltungspflicht</a></li>
              <li><a href={"#pflichten"}>Pflichten</a></li>
              <li><a href={"#abnahme"}>Abnahme</a></li>
              <li><a href={"#gewaehrleistung"}>Gewährleistung</a></li>
              <li><a href={"#virenschutz"}>Virenschutz</a></li>
              <li><a href={"#verjaehrung"}>Verjährung</a></li>
              <li><a href={"#abwerbeverbot"}>Abwerbeverbot</a></li>
              <li><a href={"#arbeitsunterlagen"}>Arbeitsunterlagen</a></li>
              <li><a href={"#datenschutz"}>Datenschutz</a></li>
              <li><a href={"#media-leistungen"}>Media-Leistungen</a></li>
              <li><a href={"#filmproduktion"}>Filmproduktion</a></li>
              <li><a href={"#wartung"}>Wartung</a></li>
              <li><a href={"#vertragslaufzeit"}>Vertragslaufzeit</a></li>
              <li><a href={"#mediation"}>Mediation</a></li>
              <li><a href={"#schlussbestimmungen"}>Schluss-Bestimmungen</a></li>
            </nav>

            <div className="text agb" style={{marginBottom:0}}>
              <a name={"gegenstand"}></a>
              <h2>Gegenstand</h2>
              <p>Die nachstehenden allgemeinen Geschäftsbedingungen gelten für alle Rechtsgeschäfte von Ziegenhagel
                Media, nachfolgend „Agentur“ genannt, mit ihren Vertragspartnern, nachstehend „Kunde“ genannt. Von
                diesen
                Geschäftsbedingungen abweichende Bedingungen des Kunden bedürfen gesonderter und schriftlicher
                Vereinbarung.
              </p>
              <p>Die Agentur erbringt Dienstleistungen aus den Bereichen Webdesign, Webprogrammierung und
                Werbefilm-Produktion.</p>

              <a name={"vertragsschluss"}></a>
              <h2>Vertragsschluss, Auftragsinhalt</h2>
              <p>Angebote der Agentur sind bis zum Vertragsschluss freibleibend.</p>
              <p>Der Auftragsinhalt bestimmt sich aus dem Auftrag des Kunden und ist in Textform zu erteilen. Wird ein
                Auftrag mündlich oder fernmündlich mitgeteilt, wird der Auftragsinhalt dem Kunden in Textform bestätigt
                und gilt bei Rückbestätigung in Textform oder wenn innerhalb von 5 (fünf) Werktagen nicht schriftlich
                widersprochen wird.</p>
              <p>Nachträgliche Änderungen oder Ergänzungen des Auftrags sowie sonstige Nebenabreden bedürfen zu ihrer
                Wirksamkeit der Textform. Mündlich oder fernmündlich getroffene Vereinbarungen werden dem Kunden nach
                dem Gespräch in Textform bestätigt und gelten bei Rückbestätigung in Textform oder wenn innerhalb von 5
                (fünf) Werktagen nicht schriftlich widersprochen wird.
              </p>
              <p>Ereignisse höherer Gewalt berechtigen die Agentur, das vom Kunden beauftragte Projekt um die Dauer der
                Behinderung und einer angemessenen Anlaufzeit hinauszuschieben. Ein Schadensersatzanspruch vom Kunden
                gegen die Agentur resultiert daraus nicht. Dies gilt auch dann, wenn dadurch für den Kunden wichtige
                Termine und/oder Ereignisse nicht eingehalten werden können und/oder nicht eintreten.</p>
              <p>Die Agentur ist berechtigt, sich bei der Erbringung von vertragsgegenständlichen Leistungen
                sachkundiger
                Dritter als Erfüllungsgehilfen zu bedienen. Die Beauftragung von Dritten erfolgt entweder im eigenen
                Namen
                oder im Namen des Kunden. Soweit die Agentur notwendige oder vereinbarte Fremdleistungen in Auftrag
                gibt,
                sind die Auftragnehmer keine Erfüllungsgehilfen der Agentur.</p>
              <p>Die Agentur ist zu Änderungen der Leistungsbeschreibung oder der Allgemeinen Geschäftsbedingungen und
                sonstiger Bedingungen berechtigt. Die Agentur wird diese Änderungen nur aus triftigen Gründen
                durchführen,
                insbesondere aufgrund neuer technischer Entwicklungen, Änderungen der Rechtsprechung oder sonstigen
                gleichwertigen Gründen. Wird durch die Änderung das vertragliche Gleichgewicht zwischen den Parteien
                erheblich gestört, so unterbleibt die Änderung. Im Übrigen bedürfen Änderungen der Zustimmung des
                Kunden.</p>
              <p>Die Agentur behält sich vor, Kostensteigerungen für laufende Leistungen an den Kunden weiter zu
                geben.</p>

              <a name={"urheberrecht"}></a>
              <h2>Urheber- und Nutzungsrechte</h2>
              <p>Der Kunde erwirbt mit der vollständigen Zahlung des vereinbarten Honorars für die vertraglich
                vereinbarte Dauer und im vertraglich vereinbarten Umfang die einfachen Nutzungsrechte an allen von der
                Agentur im Rahmen dieses Auftrages gefertigten Arbeiten. Diese Übertragung der einfachen Nutzungsrechte
                gelten, soweit eine Übertragung nach deutschem Recht möglich ist, und gelten für die vereinbarte
                Nutzung auf der im Auftrag genannten Website. Nutzungen, die darüber hinausgehen, bedürfen einer
                schriftlichen Vereinbarung im Rahmen des Auftrages oder einer gesonderten schriftlichen Nebenabrede.
                Nutzungsrechte an Arbeiten, die bei Beendigung des Vertrages noch nicht bezahlt sind, verbleiben
                vorbehaltlich anderweitig getroffener Abmachungen bei der Agentur.</p>
              <p>Die im Rahmen des Auftrages erarbeiteten Leistungen sind als persönliche geistige Schöpfungen durch das
                Urheberrechtsgesetz geschützt. Diese Regelung gilt auch dann als vereinbart, wenn die nach dem
                Urheberrechtsgesetz erforderliche Schöpfungshöhe nicht erreicht ist.</p>
              <p>Die Agentur ist berechtigt, auf allen on ihr entwickelten Werbemitteln und bei allen Werbemaßnahmen im
                branchenüblichen Umfang auf sich und gegebenenfalls auf den Urheber hinzuweisen, ohne dass dem Kunden
                dafür ein Entgeltanspruch zusteht.</p>
              <p>Die Agentur ist vorbehaltlich des Widerspruchs des Kunden dazu berechtigt, in ihrer eigenen Werbung und
                insbesondere auf ihrer Website mit Namen und Firmenlogo auf die zum Kunden bestehende oder frühere
                Geschäftsbeziehung hinzuweisen.</p>
              <p>Videoproduktionen der Agentur dürfen vom Kunden nicht bearbeitet werden. Jede Nachahmung, auch die von
                Teilen, ist unzulässig. Bei Zuwiderhandlung steht der Agentur vom Kunden mindestens ein zusätzliches
                Honorar in mindestens der 2,5-fachen Höhe des ursprünglich vereinbarten Honorars zu.</p>
              <p>Die Übertragung eingeräumter Nutzungsrechte an Dritte und/oder Mehrfachnutzungen sind, soweit nicht im
                Erstauftrag geregelt, honorarpflichtig und bedürfen der Einwilligung der Agentur.</p>
              <p>Für über die eingeräumten Nutzungsrechte hinausgehende Nutzungen gilt im Zweifel der AGD-VTV Design
                oder
                subsidiär die MFM-Bildhonorare. Über den Umfang der Nutzung steht der Agentur ein Auskunftsanspruch
                zu.</p>

              <a name={"ideenschutz"}></a>
              <h2>Ideenschutz</h2>
              <p>Hat der Kunde die Agentur vorab eingeladen, ein Konzept zu erstellen, und kommt die Agentur dieser
                Einladung noch vor Erteilung eines Auftrags nach, gilt die nachstehende Regelung:</p>
              <p>Bereits durch die Einladung und die Annahme der Einladung durch die Agentur treten der Kunde und die
                Agentur in ein Vertragsverhältnis („Pitching-Vertrag“) auf der Grundlage dieser Allgemeinen
                Geschäftsbedingungen.</p>
              <p>Der Kunde anerkennt, dass die Agentur bereits mit der Konzepterarbeitung kostenintensive Vorleistungen
                erbringt, obwohl er selbst noch keine Leistungspflichten übernommen hat.</p>
              <p>Die Parteien vereinbaren, das Konzept auch ohne Erreichen der Werkhöhe als urheberrechtlich geschützt
                zu
                behandeln. Eine Nutzung und Bearbeitung des Konzepts oder seiner Teile ohne Zustimmung der Agentur ist
                dem
                Kunden nicht gestattet. Dazu gehören insbesondere jene Elemente des Konzeptes geschützt, die eigenartig
                sind und der Vermarktungsstrategie ihre charakteristische Prägung geben, zum Beispiel Schlagwörter,
                Texte,
                Grafiken und Illustrationen und Werbemittel.</p>
              <p>Dem Kunden steht es frei nachzuweisen, dass das Konzept oder Teile davon seine eigene Vorarbeit
                darstellen.</p>

              <a name={"verguetung"}></a>
              <h2>Vergütung</h2>
              <p>Für die Leistungen der Agentur ist die vereinbarte Vergütung zuzüglich der gesetzlichen Umsatzsteuer zu
                zahlen.</p>
              <p>Zahlungen sind, wenn nicht anders vertraglich geregelt, innerhalb von 10 (zehn) Tagen nach
                Rechnungsstellung ohne Abzug fällig. Bei Überschreitung der Zahlungstermine steht der Agentur ohne
                weitere Mahnung ein Anspruch auf Verzugszinsen in Höhe von 10 (zehn) Prozentpunkte über dem
                Basiszinssatz zu. Das Recht zur Geltendmachung eines darüber hinausgehenden Schadens bleibt unberührt.
                Ist der Kunde mit fälligen Zahlungen im Verzug, ist die Agentur außerdem berechtigt, den Zugriff zu von
                der Agentur betreuten Online-Angeboten zu sperren oder für den Kunden bei Dritten gebuchte Leistungen zu
                kündigen.</p>
              <p>Erstreckt sich die Erbringung der vereinbarten Leistungen über einen Zeitraum von mehr als drei
                Monaten,
                so kann die Agentur dem Kunden Abschlagszahlungen über bereits erbrachte Teilleistungen in Rechnung
                stellen. Diese Teilleistungen müssen nicht in einer für den Kunden nutzbaren Form vorliegen.</p>
              <p>Bei einem Rücktritt des Kunden von einem Auftrag vor Beginn der vereinbarten Leistungserbringung ist
                eine Stornogebühr zu leisten. Ihre Höhe beträgt bis sechs Monate vor Beginn der Leistungserbringung 10
                %,
                ab sechs Monate bis drei Monate vor Beginn der Leistungserbringung 25 %, ab drei Monate bis vier Wochen
                vor Beginn der Leistungserbringung 50 %, ab vier Wochen bis zwei Wochen vor Beginn der
                Leistungserbringung
                80 %, ab zwei Wochen vor Beginn der Leistungserbringung 100 %. In jedem Fall sind bereits entstandene
                Kosten in voller Höhe zu ersetzen.</p>
              <p>Sofern nicht anders vereinbart, sind zwei Korrekturdurchläufe inklusive. Für jede weitere Korrektur
                wird der Agenturstundensatz von 65,– € netto innerhalb der Geschäftszeiten berechnet. Als
                Geschäftszeiten gelten Montag bis Freitag von 9.00 Uhr bis 19.00 Uhr. Für Leistungen außerhalb der
                Geschäftszeiten sowie an Wochenenden erhöht sich der Agenturstundensatz auf 80,– € netto.</p>
              <p>Für den Versand postalischer Rechnungen wird eine Gebühr von 5,– € erhoben.</p>

              <a name={"zusatzleistungen"}></a>
              <h2>Zusatzleistungen</h2>
              <p>Zusatzleistungen sind gesondert zu vereinbaren und zu vergüten.</p>
              <p>Vor einer durch Mehraufwand verursachten Überschreitung der vereinbarten Gebühren um mehr als 15 %
                weist
                die Agentur den Kunden darauf hin. Die Überschreitung gilt als vom Kunden genehmigt, wenn der Kunde
                nicht
                binnen drei Werktagen in Textform widerspricht. Eine durch Mehraufwand verursachte Überschreitung der
                vereinbarten Gebühren gilt als genehmigt.</p>

              <a name={"geheimhaltung"}></a>
              <h2>Geheimhaltungspflicht der Agentur </h2>
              <p>Die Agentur ist verpflichtet, alle Kenntnisse, die sie vom Kunden erhält, zeitlich unbeschränkt streng
                vertraulich zu behandeln und sowohl ihre Mitarbeiter, als auch von ihr herangezogene Dritte ebenfalls in
                gleicher Weise zu absolutem Stillschweigen zu verpflichten.</p>

              <a name={"pflichten"}></a>
              <h2>Pflichten des Kunden</h2>
              <p>Der Kunde stellt der Agentur alle für die Durchführung des Projekts benötigten Daten und Unterlagen
                unentgeltlich und, soweit möglich, digital zur Verfügung. Alle Arbeitsunterlagen werden von der Agentur
                sorgsam behandelt, vor dem Zugriff Dritter geschützt, nur zur Erarbeitung des jeweiligen Auftrages
                genutzt und nach Beendigung des Auftrages an den Kunden zurückgegeben beziehungsweise gelöscht.</p>
              <p>Der Kunde stellt die Agentur von Ansprüchen Dritter in Bezug auf das bereitgestellte Material frei.</p>
              <p>Die Einbindung anderer Agenturen oder Dienstleister erfolgt nur im Einvernehmen mit der Agentur.</p>
              <p>Der Kunde stellt der Agentur geeignete Texte für Bildangaben, das Impressum und die
                Datenschutzerklärung
                zur Verfügung.</p>

              <a name={"abnahme"}></a>
              <h2>Abnahme</h2>
              <p>Der Kunde ist nach Fertigstellung der beauftragten Leistung innerhalb einer Woche zur Abnahme
                verpflichtet. Mängel sind innerhalb einer Woche anzuzeigen. Wird die Anzeige unterlassen, gilt die
                Leistung als genehmigt.</p>
              <p>Änderungen nach Abnahme, auch von Teilleistungen, sind kostenpflichtig.</p>

              <a name={"gewaehrleistung"}></a>
              <h2>Gewährleistung und Haftung der Agentur</h2>
              <p>Das Risiko der rechtlichen Zulässigkeit der durch die Agentur erarbeiteten und durchgeführten
                Leistungen wird vom Kunden getragen. Das gilt insbesondere für den Fall, dass Leistungen gegen
                Vorschriften des Wettbewerbsrechts, des Urheberrechts oder sonstiger Gesetze verstoßen. Die Agentur ist
                jedoch verpflichtet, auf rechtliche Risiken hinzuweisen, sofern ihr diese bei ihrer Tätigkeit bekannt
                werden. Der Kunde stellt die Agentur von Ansprüchen Dritter frei, soweit die Agentur auf Weisung des
                Kunden gehandelt hat.</p>
              <p>Die Agentur haftet in keinem Fall wegen der in den Werbemaßnahmen enthaltenen Sachaussagen über
                Produkte
                und Leistungen des Kunden. Die Agentur haftet auch nicht für die patent-, urheber- und markenrechtliche
                Schutz- oder Eintragungsfähigkeit der im Rahmen des Auftrages gelieferten Ideen, Anregungen, Vorschläge,
                Konzeptionen und Entwürfe.</p>
              <p>Die Agentur haftet nur für Schäden, die sie oder ihre Erfüllungsgehilfen vorsätzlich oder grob
                fahrlässig herbeigeführt haben. Die Haftung der Agentur wird in der Höhe beschränkt auf die Vergütung
                der Agentur, der sich aus dem jeweiligen Auftrag ergibt. Die Haftung der Agentur für Mangelfolgeschäden
                ist ausgeschlossen, wenn und in dem Maße, wie sich die Haftung der Agentur nicht aus einer Verletzung
                der
                für die Erfüllung des Vertragszweckes wesentlichen Pflichten ergibt.</p>
              <p>Keine Bestimmung dieses Vertrages schließt die Haftung einer Partei aus (a.) bei fahrlässiger
                Verletzung
                von Leben, Körper oder Gesundheit, (b.) bei Vorsatz oder grober Fahrlässigkeit, (c.) bei Verletzung
                einer
                vertraglichen Garantie (d.) für typische, bei Vertragsschluss vorhersehbare Schäden, die aus der
                Verletzung von Vertragspflichten resultieren, die wesentliche Interessen der anderen Partei im Rahmen
                des
                Vertrages schützen („Kardinalpflichten“) oder (e.) für Ansprüche nach dem Produkthaftungsgesetz. Im
                Übrigen ist die Haftung der Agentur ausgeschlossen.</p>

              <a name={"virenschutz"}></a>
              <h2>Virenschutz und Sicherheit</h2>
              <p>Der Kunde wird darauf hingewiesen, dass er eigene Sicherheitsmaßnahmen ergreifen muss, um Schäden durch
                Viren oder einen unbefugten Zugriff Dritter abzuwenden. Derartige Maßnahmen sind nicht Gegenstand der
                vertraglichen Leistungen.</p>

              <a name={"verjaehrung"}></a>
              <h2>Verjährung</h2>
              <p>Rechte des Kunden wegen Mängeln verjähren in einem Jahr. Die Verjährungsfrist gilt nicht im Falle
                unbeschränkter Haftung nach Abschnitt 10.4.</p>
              <p>Ansprüche der Agentur auf Vergütung verjähren in fünf Jahren.</p>

              <a name={"verwertungsgesellschaften"}></a>
              <h2>Verwertungsgesellschaften</h2>
              <p>Der Kunde verpflichtet sich, eventuell anfallende Gebühren an Verwertungsgesellschaften wie
                beispielsweise an die Gema abzuführen. Werden diese Gebühren von der Agentur verauslagt, so verpflichtet
                sich der Kunde, diese der Agentur gegen Nachweis zu erstatten. Dies kann auch nach Beendigung des
                Vertragsverhältnisses erfolgen.</p>
              <p>Der Kunde ist darüber informiert, dass bei der Auftragsvergabe im künstlerischen, konzeptionellen und
                werbeberaterischen Bereich an eine nicht-juristische Person eine Künstlersozialabgabe an die
                Künstlersozialkasse zu leisten ist. Diese Abgabe darf vom Kunden nicht von der Agenturrechnung in Abzug
                gebracht werden. Für die Einhaltung der Anmelde- und Abgabepflicht ist der Kunde zuständig und selbst
                verantwortlich.</p>

              <a name={"abwerbeverbot"}></a>
              <h2>Abwerbeverbot</h2>
              <p>Von der Agentur eingeschaltete freie Mitarbeiter oder Dritte sind Erfüllungs- oder Verrichtungsgehilfen
                der Agentur. Der Kunde verpflichtet sich, für die Dauer der auf den Abschluss des Auftrages folgenden 12
                (zwölf) Monate ohne Zustimmung der Agentur weder unmittelbar noch mittelbar zu beauftragen oder
                anzustellen.</p>

              <a name={"arbeitsunterlagen"}></a>
              <h2>Arbeitsunterlagen</h2>
              <p>Alle Arbeitsunterlagen, Daten und Aufzeichnungen, die im Rahmen der Auftragserarbeitung von der Agentur
                angefertigt werden, verbleiben bei der Agentur. Die Herausgabe dieser Unterlagen und Daten kann vom
                Kunden
                nicht gefordert werden. Die Agentur schuldet mit der Bezahlung des vereinbarten Honorars die vereinbarte
                Leistung, nicht jedoch die zu diesem Ergebnis führenden Zwischenschritte in Form von Skizzen, Entwürfen,
                Produktionsdaten etc. Das Vorstehende gilt insbesondere auch für Quellcode und Video-Rohmaterial.</p>

              <a name={"datenschutz"}></a>
              <h2>Datenschutz</h2>
              <p>Bezüglich der Verarbeitung personenbezogener Daten gilt die Datenschutzerklärung der Agentur.</p>

              <a name={"media-leistungen"}></a>
              <h2>Media-Leistungen</h2>
              <p>Projekte im Bereich Media-Planung besorgt die Agentur nach bestem Wissen und Gewissen auf Basis der ihr
                zugänglichen Unterlagen der Medien und der allgemein zugänglichen Marktforschungsdaten. Einen bestimmten
                werblichen Erfolg schuldet die Agentur dem Kunden durch diese Leistungen nicht.</p>
              <p>Die Agentur verpflichtet sich, alle Vergünstigungen, Sonderkonditionen und Rabatte im Sinne des
                Auftraggebers bei der Media-Schaltung zu berücksichtigen und diese an den Kunden weiterzugeben.</p>
              <p>Bei umfangreichen Media-Leistungen ist die Agentur nach Absprache berechtigt, einen bestimmten Anteil
                der
                Fremdkosten dem Kunden vorab in Rechnung zu stellen und die Einbuchung bei den entsprechenden Medien
                erst
                nach Zahlungseingang vorzunehmen. Für eine eventuelle Nichteinhaltung eines Schalttermines durch einen
                verspäteten Zahlungseingang haftet die Agentur nicht.</p>
              <p>Die Agentur weist den Kunden vor Auftragserteilung ausdrücklich darauf hin, dass die Anbieter von
                „Social-Media-Kanälen“ (z.B. facebook, im Folgenden kurz: Anbieter) es sich in ihren Nutzungsbedingungen
                vorbehalten, Werbeanzeigen und -auftritte aus beliebigen Grund abzulehnen oder zu entfernen. Die
                Anbieter
                sind demnach nicht verpflichtet, Inhalte und Informationen an die Nutzer weiterzuleiten. Es besteht
                daher
                das von der Agentur nicht kalkulierbare Risiko, dass Werbeanzeigen und -auftritte grundlos entfernt
                werden. Im Fall einer Beschwerde eines anderen Nutzers wird zwar von den Anbietern die Möglichkeit einer
                Gegendarstellung eingeräumt, doch erfolgt auch in diesem Fall eine sofortige Entfernung der Inhalte. Die
                Wiedererlangung des ursprünglichen, rechtmäßigen Zustandes kann in diesem Fall einige Zeit in Anspruch
                nehmen. Die Agentur arbeitet auf der Grundlage dieser Nutzungsbedingungen der Anbieter, auf die sie
                keinen
                Einfluss hat, und legt diese auch einem Auftrag des Kunden zu Grunde. Ausdrücklich anerkennt der Kunde
                mit
                der Auftragserteilung, dass diese Nutzungsbedingungen die Rechte und Pflichten eines allfälligen
                Vertragsverhältnisses (mit-)bestimmen. Die Agentur beabsichtigt, den Auftrag des Kunden nach bestem
                Wissen
                und Gewissen auszuführen und die Richtlinien von „Social Media Kanälen“ einzuhalten. Aufgrund der
                derzeit
                gültigen Nutzungsbedingungen und der einfachen Möglichkeit jedes Nutzers, Rechtsverletzungen zu
                behaupten
                und so eine Entfernung der Inhalte zu erreichen, kann die Agentur aber nicht dafür einstehen, dass die
                beauftragte Kampagne auch jederzeit abrufbar ist.</p>

              <a name={"filmproduktion"}></a>
              <h2>Filmproduktion</h2>
              <p>Die Herstellung eines Films erfolgt auf Grundlage eines vom Kunden zur Verfügung gestellten oder
                genehmigten Drehbuchs, Storyboards, Layoutfilms und/oder des schriftlich niedergelegten Ergebnisses der
                letzten Besprechung vor Drehbeginn.</p>
              <p>Wenn der Kunde die Nutzung eigenen Produktionsmaterials wünscht, verpflichtet er sich, dieses in einem
                gebräuchlichen und verwertbaren Format zur Verfügung zu stellen. Das Material muss in einem für seine
                Nutzung angemessenen Zeitraum vor Beginn des vereinbarten Drehtermins übergeben werden. Muss dieses
                Material von der Agentur angepasst werden, trägt der Kunde die hierfür entstandenen Kosten.</p>
              <p>Die Agentur haftet bei Verlust oder Beschädigung überlassenen Materials nur im Rahmen einer
                Ersatzlieferung des verlorenen oder beschädigten Rohmaterials. Für den Verlust von Daten und Programmen
                auf diesem Material übernimmt die Agentur keine Haftung.</p>
              <p>Kosten für Modelle, Locations, externe Dienstleister, Stock-Material, Reisekosten, Software und Musik
                werden vom Kunden getragen. Wünscht der Kunde die Nutzung eines bestimmten Musiktitels, so garantiert
                er,
                dass er alle Rechte an dem verwendeten Material besitzt.</p>
              <p>Wetterbedingte Verschiebungen und Abbrüche des Drehs (Wetterrisiko) sind in den kalkulierten
                Produktionskosten nicht enthalten. Die anfallenden Zusatzkosten werden in Rechnung gestellt und
                gesondert
                ausgewiesen.</p>

              <a name={"wartung"}></a>
              <h2>Wartung</h2>
              <p>Eine Wartung erstellter Websites erfolgt nur nach entsprechender Beauftragung.</p>
              <p>Die Wartung umfasst keine Reparaturmaßnahmen an der Website, die durch das Fehlverhalten des Kunden
                hervorgerufen wurden. Die Wartung umfasst auch keine inhaltlichen Änderungen oder Änderung am Design.
                Bei
                Pflegeplänen mit inklusiv-Entwicklerzeiten verfallen nicht genutzte Zeiten am Ende eines Monats.</p>
              <p>Die Wartungspauschale wird jährlich und im Voraus abgerechnet.</p>

              <a name={"vertragslaufzeit"}></a>
              <h2>Vertragslaufzeit und Kündigung</h2>
              <p>Ist ein Auftrag auf unbestimmte Zeit erteilt, kann dieser mit einer Frist von drei Monaten zum
                Jahresende
                gekündigt werden. Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt von dieser Regelung
                unberührt. Die Kündigung bedarf der Schriftform.</p>

              <a name={"mediation"}></a>
              <h2>Mediation</h2>
              <p>Kommt es im Laufe oder nach Beendigung eines Auftrages zu einem Streitfall bezüglich des beauftragten
                Projektes, so ist vor der Einleitung eines gerichtlichen Verfahrens ein außergerichtliches
                Mediationsverfahren zu durchlaufen. Bei Streitigkeiten in Fragen der Qualitätsbeurteilung oder bei der
                Höhe der Honorierung werden externe Gutachten erstellt, um möglichst eine außergerichtliche Einigung zu
                erzielen. Die Kosten hierfür werden von Kunden und Agentur geteilt.</p>

              <a name={"schlussbestimmungen"}></a>
              <h2>Schlussbestimmungen</h2>
              <p>Der Kunde ist nicht dazu berechtigt, Ansprüche aus dem Vertrag abzutreten. Die Agentur ist berechtigt,
                bestehende Verträge auf eine andere Rechtspersönlichkeit zu übertragen.</p>
              <p>Eine Aufrechnung oder die Geltendmachung eines Zurückbehaltungsrechts durch den Kunden ist nur mit
                anerkannten oder rechtskräftig festgestellten Gegenansprüchen zulässig.</p>
              <p>Es gilt deutsches Recht. Erfüllungsort und Gerichtsstand ist Mainz.</p>
              <p>Sollte eine Bestimmung dieser Allgemeinen Geschäftsbedingungen ganz oder teilweise unwirksam sein oder
                ihre Rechtswirksamkeit zu einem späteren Zeitpunkt verlieren, so wird hierdurch die Gültigkeit der
                übrigen Bestimmungen nicht berührt. Anstelle der unwirksamen Bestimmung soll im Wege der
                Vertragsanpassung eine andere angemessene Regelung gelten, die wirtschaftlich dem am Nächsten kommt, was
                die Vertragsparteien gewollt hätten, wenn ihnen die Unwirksamkeit der Regelung bekannt gewesen wäre.</p>

              <p className={"no-count"} style={{margin:0}}>Stand: 17. Januar 2023</p>

            </div>

          </div>
          <div style={{width:1,height:100}}></div>
        </div>
      </Layout>)
  }
}
